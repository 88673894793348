.car_search {
  display: flex;
  overflow: hidden;
  flex-direction: column;
  height: 100%;
}
.nav {
  display: flex;
  overflow: hidden;
  flex-direction: row;
  flex: 1 1 0%;
  height: 100%;
}

.content {
  padding: 0.75rem;
  flex: 1 1 0%;
}

.content_nav_bar {
  display: flex;
  margin-left: 0.75rem;
  margin-left: 0.875rem;
  flex-direction: row;
  color: #4b5563;
  font-weight: bolder;
}
.item {
  overflow: scroll;
  padding: 1.5rem;
  height: 100%;
}

.mouseover {
  cursor: pointer;
}

.tab_item {
  border-color: #dc2626 !important;
}

.space {
  flex: 1 1 0%;
}

.btn {
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  padding-left: 0.75rem;
  padding-right: 0.75rem;
  border-bottom-width: 2px;
  border-bottom-style: solid;
  border-color: #e5e7eb;
  font-size: 0.875rem;
  line-height: 1.25rem;
  margin: 0 6px;
}
