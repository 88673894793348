.h-full {
  height: 100%;
}

.w-screen {
  width: 100vw;
}

.overflow-scroll {
  overflow: scroll;
}

.overflow-x-hidden {
  overflow-x: hidden;
}

.p-6 {
  padding: 1.5rem;
}
