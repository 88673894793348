.root {
  display: flex;
  flex-direction: column;
  align-items: center;
  border-left-width: 2px;
  width: 3rem;
  height: 100%;
  color: #4a4e51;
  border-left-style: solid;
  > * {
    margin-top: 0.75rem;
  }
}
