.mb-3 {
  margin-bottom: 0.75rem;
}

.mb-9 {
  margin-bottom: 2.25rem;
}

.mt-3 {
  margin-top: 0.75rem;
}

.mt-9 {
  margin-top: 2.25rem;
}

.flex {
  display: flex;
}

.h-2\/5 {
  height: 40%;
}

.h-5\/6 {
  height: 83.333333%;
}

.h-9 {
  height: 2.25rem;
}

.h-full {
  height: 100%;
}

.w-full {
  width: 100%;
}

.flex-1 {
  flex: 1 1 0%;
}

.cursor-pointer {
  cursor: pointer;
}

.flex-row {
  flex-direction: row;
}

.flex-col {
  flex-direction: column;
}

.items-center {
  align-items: center;
}

.justify-center {
  justify-content: center;
}

.space-x-2 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(0.5rem * var(--tw-space-x-reverse));
  margin-left: calc(0.5rem * calc(1 - var(--tw-space-x-reverse)));
}

.space-x-6 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(1.5rem * var(--tw-space-x-reverse));
  margin-left: calc(1.5rem * calc(1 - var(--tw-space-x-reverse)));
}

.place-self-end {
  place-self: end;
}

.overflow-hidden {
  overflow: hidden;
}

.overflow-x-hidden {
  overflow-x: hidden;
}

.overflow-y-scroll {
  overflow-y: scroll;
}

.border-b-2 {
  border-bottom-width: 2px;
}

.bg-\[\#15577E\] {
  --tw-bg-opacity: 1;
  background-color: rgb(21 87 126);
}

.bg-\[\#EFF0F1\] {
  --tw-bg-opacity: 1;
  background-color: rgb(239 240 241);
}

.bg-red-600 {
  --tw-bg-opacity: 1;
  background-color: rgb(220 38 38);
}

.px-3 {
  padding-left: 0.75rem;
  padding-right: 0.75rem;
}

.px-4 {
  padding-left: 1rem;
  padding-right: 1rem;
}

.py-1 {
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
}

.py-2 {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.text-sm {
  font-size: 0.875rem;
  line-height: 1.25rem;
}

.font-semibold {
  font-weight: 600;
}

.text-gray-500 {
  --tw-text-opacity: 1;
  color: rgb(107 114 128);
}

.text-gray-700 {
  --tw-text-opacity: 1;
  color: rgb(55 65 81);
}

.text-white {
  --tw-text-opacity: 1;
  color: rgb(255 255 255);
}
