.mb-3 {
  margin-bottom: 0.75rem;
}

.ml-2 {
  margin-left: 0.5rem;
}

.ml-3 {
  margin-left: 0.75rem;
}

.ml-6 {
  margin-left: 1.5rem;
}

.mr-1 {
  margin-right: 0.25rem;
}

.mr-6 {
  margin-right: 1.5rem;
}

.mt-3 {
  margin-top: 0.75rem;
}

.mt-6 {
  margin-top: 1.5rem;
}

.flex {
  display: flex;
}

.h-5 {
  height: 1.25rem;
}

.h-6 {
  height: 1.5rem;
}

.h-72 {
  height: 18rem;
}

.h-8 {
  height: 2rem;
}

.h-9 {
  height: 2.25rem;
}

.h-\[1px\] {
  height: 1px;
}

.h-\[480px\] {
  height: 480px;
}

.h-full {
  height: 100%;
}

.min-h-\[480px\] {
  min-height: 480px;
}

.w-12 {
  width: 3rem;
}

.w-16 {
  width: 4rem;
}

.w-3 {
  width: 0.75rem;
}

.w-36 {
  width: 9rem;
}

.w-6 {
  width: 1.5rem;
}

.w-64 {
  width: 16rem;
}

.w-8 {
  width: 2rem;
}

.w-9 {
  width: 2.25rem;
}

.w-full {
  width: 100%;
}

.min-w-0 {
  min-width: 0px;
}

.flex-1 {
  flex: 1 1 0%;
}

.cursor-pointer {
  cursor: pointer;
}

.flex-row {
  flex-direction: row;
}

.flex-col {
  flex-direction: column;
}

.items-center {
  align-items: center;
}

.justify-center {
  justify-content: center;
}

.justify-around {
  justify-content: space-around;
}

.space-x-2 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(0.5rem * var(--tw-space-x-reverse));
  margin-left: calc(0.5rem * calc(1 - var(--tw-space-x-reverse)));
}

.space-x-3 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(0.75rem * var(--tw-space-x-reverse));
  margin-left: calc(0.75rem * calc(1 - var(--tw-space-x-reverse)));
}

.space-x-6 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(1.5rem * var(--tw-space-x-reverse));
  margin-left: calc(1.5rem * calc(1 - var(--tw-space-x-reverse)));
}

.space-y-2 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(0.5rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(0.5rem * var(--tw-space-y-reverse));
}

.space-y-3 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(0.75rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(0.75rem * var(--tw-space-y-reverse));
}

.overflow-hidden {
  overflow: hidden;
}

.overflow-scroll {
  overflow: scroll;
}

.overflow-x-hidden {
  overflow-x: hidden;
}

.rounded-full {
  border-radius: 9999px;
}

.border-2 {
  border-width: 2px;
}

.border-\[1px\] {
  border-width: 1px;
}

.border-b-2 {
  border-bottom-width: 2px;
}

.border-none {
  border-style: none;
}

.border-\[\#BCBDBE\] {
  --tw-border-opacity: 1;
  border-color: rgb(188 189 190);
}

.border-red-600 {
  --tw-border-opacity: 1;
  border-color: rgb(220 38 38);
}

.bg-\[\#15577E\] {
  --tw-bg-opacity: 1;
  background-color: rgb(21 87 126);
}

.bg-\[\#494E51\] {
  --tw-bg-opacity: 1;
  background-color: rgb(73 78 81);
}

.bg-\[\#7B7E80\] {
  --tw-bg-opacity: 1;
  background-color: rgb(123 126 128);
}

.bg-\[\#AFB1B2\] {
  --tw-bg-opacity: 1;
  background-color: rgb(175 177 178);
}

.bg-\[\#BCBDBE\] {
  --tw-bg-opacity: 1;
  background-color: rgb(188 189 190);
}

.bg-\[\#EFF0F1\] {
  --tw-bg-opacity: 1;
  background-color: rgb(239 240 241);
}

.bg-red-600 {
  --tw-bg-opacity: 1;
  background-color: rgb(220 38 38);
}

.bg-transparent {
  background-color: transparent;
}

.bg-white {
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255);
}

.p-3 {
  padding: 0.75rem;
}

.p-6 {
  padding: 1.5rem;
}

.px-2 {
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}

.px-3 {
  padding-left: 0.75rem;
  padding-right: 0.75rem;
}

.py-1 {
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
}

.py-2 {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.pl-3 {
  padding-left: 0.75rem;
}

.pl-6 {
  padding-left: 1.5rem;
}

.pr-3 {
  padding-right: 0.75rem;
}

.pr-6 {
  padding-right: 1.5rem;
}

.text-sm {
  font-size: 0.875rem;
  line-height: 1.25rem;
}

.text-xs {
  font-size: 0.75rem;
  line-height: 1rem;
}

.font-bold {
  font-weight: 700;
}

.font-semibold {
  font-weight: 600;
}

.text-\[\#494E51\] {
  --tw-text-opacity: 1;
  color: rgb(73 78 81);
}

.text-black {
  --tw-text-opacity: 1;
  color: rgb(0 0 0);
}

.text-gray-500 {
  --tw-text-opacity: 1;
  color: rgb(107 114 128);
}

.text-gray-600 {
  --tw-text-opacity: 1;
  color: rgb(75 85 99);
}

.text-gray-700 {
  --tw-text-opacity: 1;
  color: rgb(55 65 81);
}

.text-red-600 {
  --tw-text-opacity: 1;
  color: rgb(220 38 38);
}

.text-white {
  --tw-text-opacity: 1;
  color: rgb(255 255 255);
}

.filter {
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast)
    var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate)
    var(--tw-sepia) var(--tw-drop-shadow);
}

.focus\:outline-none:focus {
  outline: 2px solid transparent;
  outline-offset: 2px;
}
