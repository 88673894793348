.search_box {
  display: flex;
  flex-direction: row;
  align-items: center;
  border-width: 2px;
  width: 16rem;
  border-style: solid;
  border-color: #e5e7eb;
}
.search_icon {
  margin-left: 0.5rem;
}
.search_input {
  flex: 1 1 0%;
  border-style: none;
  min-width: 0;
  height: 100%;
  font-size: 0.875rem;
  line-height: 1.25rem;
  background-color: transparent;
}
.search_icon_2 {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 2rem;
  height: 100%;
  color: #ffffff;
  cursor: pointer;
  background: #494e51;
}

.content {
  display: flex;
  margin-top: 3rem;
  flex-direction: row;
  height: 16rem;
}

.car_image {
  position: relative;
  width: 16rem;
}

.car_info {
  margin-left: 6rem;
  flex: 1 1 0%;
  font-size: 0.875rem;
  line-height: 1.25rem;
}

.car_info_text_box {
  display: flex;
  padding: 0.75rem;
  flex-direction: row;
  border-bottom-width: 2px;
}
.car_info_text_box_title {
  width: 20rem;
}

.car_info_card {
  display: flex;
  margin-top: 0.25rem;
  flex-direction: row;
  align-items: center;
  font-size: 0.875rem;
  line-height: 1.25rem;
  font-weight: 600;
  padding-left: 0.75rem;
  padding-right: 0.75rem;
}
.car_info_card_icon {
  display: flex;
  justify-content: center;
  align-items: center;
  color: #ffffff;
}

.detail_box {
  display: flex;
  flex-direction: row;

  > div:nth-child(2) {
    margin-left: 0.75rem;
  }
}

.detail {
  margin-top: 0.75rem;
  flex: 1 1 0%;
  border-width: 2px;
  border-style: solid;
  border-color: #e5e7eb;
}

.detail_nav {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  font-size: 0.875rem;
  line-height: 1.25rem;
  background: #eff0f1;
  > p {
    padding: 0.75rem;
  }
}

.detail_nav_icon_box {
  flex: 1 1 0%;
}

.detail_nav_notify {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 9999px;
  width: 1.5rem;
  height: 1.25rem;
  color: #ffffff;
  background-color: #dc2626;
}

.detail_content {
  height: 180px;
}
