.root {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}
.content {
  font-weight: 600;
  color: #1f2937;
}
