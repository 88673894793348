.h-full {
  height: 100%;
}

.w-64 {
  width: 16rem;
}

.w-fit {
  width: fit-content;
}

.space-y-2 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(0.5rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(0.5rem * var(--tw-space-y-reverse));
}

.overflow-scroll {
  overflow: scroll;
}

.rounded-md {
  border-radius: 0.375rem;
}

.bg-blue-200 {
  --tw-bg-opacity: 1;
  background-color: rgb(191 219 254);
}

.bg-blue-500 {
  --tw-bg-opacity: 1;
  background-color: rgb(59 130 246);
}

.p-1 {
  padding: 0.25rem;
}

.p-2 {
  padding: 0.5rem;
}

.p-3 {
  padding: 0.75rem;
}

.text-sm {
  font-size: 0.875rem;
  line-height: 1.25rem;
}

.font-bold {
  font-weight: 700;
}

.text-\[\#A5A5A5\] {
  --tw-text-opacity: 1;
  color: rgb(165 165 165);
}

.text-blue-500 {
  --tw-text-opacity: 1;
  color: rgb(59 130 246);
}

.text-white {
  --tw-text-opacity: 1;
  color: rgb(255 255 255);
}
